import svg4everybody from 'svg4everybody';
// import CookieConsentConfig from "./utils/cookieconsent-config";
import { $html } from './utils/dom';
import { ENV } from './config';

// Dynamic imports for development mode only
let gridHelper;
(async () => {
    if (ENV.IS_DEV) {
        const gridHelperModule = await import('./utils/grid-helper');
        gridHelper = gridHelperModule?.gridHelper;
    }
})();

// let CookieConsent;
// (async () => {
//     CookieConsent = await import('vanilla-cookieconsent');
//     // CookieConsentConfig.language.default = $html.getAttribute('lang').split('-')[0];
//     CookieConsent.run(CookieConsentConfig);
//     $html.classList.add('cc--darkmode');
// })();

export default function () {
    // -------------------------------------
    // Use external SVG spritemaps
    // -------------------------------------
    svg4everybody();
    
    // -------------------------------------
    // Grid helper
    // -------------------------------------
    gridHelper?.();
}
