import SplitType from 'split-type';

function textAnimations() {
    
    // Automatic text split
    if (document.querySelectorAll('[data-split]')) {
        setTimeout(() => {
            document.querySelectorAll('[data-split]').forEach((splitElt) => {
                let splitType = (splitElt.getAttribute('data-split')) ? splitElt.getAttribute('data-split') : 'lines, words';
                let split = new SplitType(splitElt, { types: splitType });
                
                if (split.lines) {
                    split.lines.forEach((l, index) => {
                        l.style.setProperty('--l-index', index);
                    });
                }
                if (split.words) {
                    split.words.forEach((w, index) => {
                        w.style.setProperty('--w-index', index);
                    });
                }
                if (split.chars) {
                    split.chars.forEach((c, index) => {
                        c.style.setProperty('--c-index', index);
                    });
                }
            });
        }, 0);
    }
}


export { textAnimations };
