/**
 * Grid Helper
 *
 * Provides a grid based on the design guidelines and is helpful for web integration.
 *
 * - `Control + g` to toggle the grid
 *
 */

/**
 * @typedef {Object} GridHelperReference
 *
 * @property {string} [gutterCssVar=GRID_HELPER_GUTTER_CSS_VAR] - CSS variable used to define grid gutters.
 * @property {string} [marginCssVar=GRID_HELPER_MARGIN_CSS_VAR] - CSS variable used to define grid margins.
 * @property {string} [rgbaColor=GRID_HELPER_RGBA_COLOR]        - RGBA color for the grid appearence.
 */

const GRID_HELPER_GUTTER_CSS_VAR = '--grid-gutter';
const GRID_HELPER_MARGIN_CSS_VAR = '--grid-margin';
const GRID_HELPER_RGBA_COLOR = '--grid-color';
const GRID_HELPER_STATES = 2;

/**
 * Create a grid helper
 *
 * @param {GridHelperReference}
 *
 */
function gridHelper({
    gutterCssVar = GRID_HELPER_GUTTER_CSS_VAR,
    marginCssVar = GRID_HELPER_MARGIN_CSS_VAR,
    rgbaColor = GRID_HELPER_RGBA_COLOR
} = {}) {

    // Set grid container
    const $gridContainer = document.createElement('div');
    $gridContainer.setAttribute('data-state', 0);
    $gridContainer.style.setProperty('--gh-opacity', 0);
    document.body.append($gridContainer);

    // Set grid appearence
    setGridHelperColumns($gridContainer, rgbaColor);
    setGridHelperStyles($gridContainer, gutterCssVar, marginCssVar);
    setGridToggle($gridContainer, rgbaColor);

    // Set grid interactivity
    setGridEvents($gridContainer, rgbaColor);
}

/**
 * Set grid container styles
 *
 * @param {HTMLElement} $container - DOM Element that contains a list of generated columns
 * @param {string} gutterCssVar    - CSS variable used to define grid gutters.
 * @param {string} marginCssVar    - CSS variable used to define grid margins.
 *
 */
function setGridHelperStyles($container, gutterCssVar, marginCssVar) {
    const elStyles = $container.style;
    elStyles.zIndex = '10000';
    elStyles.position = 'fixed';
    elStyles.top = '0';
    elStyles.left = '0';
    elStyles.display = 'flex';
    elStyles.width = '100%';
    elStyles.height = '100%';
    elStyles.columnGap = `var(${gutterCssVar}, 0)`;
    elStyles.paddingLeft = `var(${marginCssVar}, 0)`;
    elStyles.paddingRight = `var(${marginCssVar}, 0)`;
    elStyles.pointerEvents = 'none';
    elStyles.visibility = 'hidden';
}

/**
 * Set grid columns
 *
 * @param {HTMLElement} $container - DOM Element that will contain a list of generated columns
 * @param {string} rgbaColor       - RGBA color to stylize the generated columns
 *
 */
function setGridHelperColumns($container, rgbaColor) {
    // Clear columns
    $container.innerHTML = '';

    // Loop through columns
    const columns = Number(
        window.getComputedStyle($container).getPropertyValue('--grid-columns')
    );

    let $col;
    for (var i = 0; i < columns; i++) {
        $col = document.createElement('div');
        $col.style.flex = '1 1 0';
        $col.style.backgroundColor = `var(${rgbaColor}, pink)`;
        $col.style.opacity = `var(--gh-opacity)`;
        $container.appendChild($col);
    }
}

function setGridToggle($container, rgbaColor) {
    $toggle = document.createElement('button');
    $toggle.style.zIndex = '10020';
    $toggle.style.position = 'fixed';
    $toggle.style.bottom = '30px';
    $toggle.style.right = '30px';
    $toggle.style.width = "50px";
    $toggle.style.height = "50px";
    $toggle.style.borderRadius = "50px";
    $toggle.style.padding = "12px";
    $toggle.style.background = `var(${rgbaColor}, pink)`;
    $toggle.style.border = "0";
    $toggle.style.boxShadow = "0 35px 40px 0 rgba(50,51,94,0.25)";
    $toggle.style.cursor = "pointer";
    $toggle.style.textAlign = "center";
    $toggle.innerHTML = '<svg width="21" height="20" viewBox="0 0 21 20" version="1" xmlns="http://www.w3.org/2000/svg"> <path d="M0 0h5v20H0zM16 0h5v20h-5zM8 0h5v20H8z" fill="#FFF" /> </svg>';
    document.body.appendChild($toggle);

    $toggle.addEventListener('click', () => { toggleGrid($container); });
}

/**
 * Set grid events
 *
 * Resize to rebuild columns
 * Keydown/Keyup to toggle the grid display
 *
 * @param {HTMLElement} $container - DOM Element that contains a list of generated columns
 * @param {string} rgbaColor       - RGBA color to stylize the generated columns
 *
 */
function setGridEvents($container, rgbaColor) {
    // Handle resize
    window.addEventListener(
        'resize', () => {
            setGridHelperColumns($container, rgbaColor)
        }
    );

    // Toggle grid
    let ctrlDown = false;

    document.addEventListener('keydown', (e) => {
        if (e.key == 'Control') {
            ctrlDown = true;
        } else {
            if (ctrlDown && e.key == 'g') {
                toggleGrid($container)
            }
        }
    });

    document.addEventListener('keyup', (e) => {
        if (e.key == 'Control') {
            ctrlDown = false;
        }
    });
}

function toggleGrid ($container) {
    let state = parseInt($container.getAttribute('data-state'));
    let newState = state == GRID_HELPER_STATES ? 0 : state + 1;    
    $container.setAttribute('data-state', newState);
    
    $container.style.setProperty('--gh-opacity', newState * 0.2);
    if (newState == 0) {
        $container.style.visibility = 'hidden';
    } else {
        $container.style.visibility = 'visible';
    }
}

export { gridHelper };
