import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';
import gsap from "gsap";

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.scroll = new LocomotiveScroll({
            modularInstance: this,
            autoStart: false,
            scrollCallback: ({ scroll, limit, velocity, direction, progress }) => this.onScroll({ scroll, limit, velocity, direction, progress }),
        });

        // Force scroll to top
        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual';
            window.scrollTo(0, 0);
        }

        this.initScrollToElements();

        window.addEventListener('scroll-start', () => { this.start(); });
        window.addEventListener('scroll-stop', () => { this.stop(); });
        window.addEventListener('scroll-resize', () => { this.resize(); });

        const card = document.querySelector('.c-card');

        window.addEventListener('toggleCard', (e) => {
            const { target, way, from } = e.detail;

            if (way == 'enter') {
                card.classList.add('-visible');
            }
            if (way == 'leave' && from == 'end') {
                card.classList.remove('-visible');
            }
        });
    }

    start() {
        this.scroll.start();
    }

    stop() {
        this.scroll.stop();
    }

    resize() {
        this.scroll.resize();
    }
    
    onScroll({ scroll, limit, velocity, direction, progress }) {
        gsap.set('.picto--rotate', { rotate: scroll/5 });
    }

    scrollTo(target, delay = 0) {
        setTimeout(() => {
            this.scroll?.scrollTo(target, {
                duration: 1,
                lerp: 0.1,
                lock: false,
                easing: (x) => x === 1 ? 1 : 1 - Math.pow(2, -10 * x)
            });
        }, delay);
    }

    initScrollToElements() {
        this.el.querySelectorAll('[data-scroll-to]').forEach((elt) => {
            elt.addEventListener('click', (e) => {
                e.preventDefault();
                let target = document.querySelector('[data-scroll-target="' + elt.getAttribute('data-scroll-to') + '"]');
                let delay = elt.getAttribute('data-scroll-to-delay') ? parseInt(elt.getAttribute('data-scroll-to-delay')) : 0;
                if (target) {
                    this.scrollTo(target, delay);
                }
            });
        });
    }

    scrollTop(force) {
        if (!this.scroll) return;
        if (force) {
            this.scroll.scrollTo('top', { immediate: true });
        } else {
            scrollTo('top');
        }
    } 

    destroy() {
        this.scroll.destroy();
    }
}
