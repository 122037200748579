import modular from 'modujs';
import * as modules from './modules';
import globals from './globals';
import shared from './shared';
import { $html } from './utils/dom';
import { ENV, CSS_CLASS, CUSTOM_EVENT } from './config'
import gsap from "gsap";

const app = new modular({
    modules,
});

function init() {
    globals();
    shared();

    app.init(app);

    $html.classList.add(CSS_CLASS.LOADED, CSS_CLASS.READY);
    $html.classList.remove(CSS_CLASS.LOADING);
}

////////////////
// Execute
////////////////
window.addEventListener('load', () => {

    init();
    window.dispatchEvent(new Event("page-loaded"));

});
