import { module } from 'modujs';
import modularLoad from 'modularload';
import shared from '../shared';
import { $body } from '../utils/dom';
import gsap from "gsap";
import SplitType from 'split-type';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        const load = new modularLoad({
            enterDelay: 1500,
            transitions: {
                customTransition: {}
            }
        });

        this.DOM = {
            loading: document.querySelector('.o-loading'),
            name: document.querySelector('.o-loading_name'),
            job: document.querySelector('.o-loading_job'),
            h1: document.querySelector('.c-heading.-h1')
        };

        window.addEventListener('page-loaded', () => { this.play(); });

        load.on('loading', (oldContainer, newContainer) => {
            this.show();
            this.call('stop', 'Scroll');
        });

        load.on('loaded', (transition, oldContainer, newContainer) => {
            shared();

            // Update body class
            this.hide();

            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');
        });
        
    }

    play (delay = 0) {
        this.DOM.loading.classList.remove('-hidden');

        let tl = gsap.timeline({ delay: delay, onComplete: () => {
            this.DOM.loading.classList.add('-hidden');
            this.call('start', 'Scroll');
        } });

        tl.to(this.DOM.name.querySelectorAll('.word'),
            { y: "0", rotate: 0, opacity: 1, duration: 1, ease: "expo.inOut", stagger: 0.1 },
            0
        );
        tl.to(this.DOM.job.querySelectorAll('.word'),
            { y: "0", rotate: 0, opacity: 1, duration: 1, ease: "expo.inOut", stagger: 0.05 },
            0.3
        );

        tl.to(this.DOM.name.querySelectorAll('.word'),
            { y: "-100", rotate: 0, opacity: 0, duration: 0.6, ease: "expo.inOut" },
            3
        );
        tl.to(this.DOM.job.querySelectorAll('.word'),
            { y: "-100", rotate: 0, opacity: 0, duration: 0.6, ease: "expo.inOut" },
            3
        );

        let split = new SplitType(this.DOM.h1, { types: 'lines,words' });
        this.DOM.h1.classList.remove('-hidden');

        tl.to(split.words, {
            y: "0", rotate: 0, opacity: 1,
            duration: 1, ease: "expo.inOut", stagger: 0.1,
            onStart: () => {
                this.call('show', 'Navbar');
                setTimeout(() => {
                    document.querySelector('.c-card').classList.add('-visible');
                }, 500);
            }},
            3
        );



        // setTimeout(() => {
        //     // Animate page content
        //     window.dispatchEvent(new Event("p-loaded"));
        // }, 400);
    }

    setNewBodyClass () {
        if (this.el.querySelector('[data-bodyclass]')) {
            let bodyClass = this.el.querySelector('[data-bodyclass]').getAttribute('data-bodyclass');
            $body.className = bodyClass;
        }
    }
}
