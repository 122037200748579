import { module } from 'modujs';
import { debounce } from '../utils/tickers';
import gsap from "gsap";

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() { }

    show() {
        this.el.classList.remove('-loading');
        
        let tl = gsap.timeline({ delay: 0 });

        tl.to(this.el.querySelector('.c-navbar_loading'), {
            opacity: 0, y: -50, duration: 0.15, ease: "none"
        });

        tl.fromTo(this.el.querySelector('.c-navbar_name'),
            { opacity: 0, y: 40 },
            { opacity: 1, y: 0, duration: 0.6, ease: "expo.out" }
        , 0.25);

        tl.to(this.el.querySelector('.c-navbar_job'), {
            opacity: 1, duration: 0.6, ease: "expo.out"
        }, 0.5);

        tl.to(this.el.querySelector('.c-navbar_status'), {
            opacity: 1, duration: 0.6, ease: "expo.out"
        }, 0.6);

    }
}
